<section id="pricing-intro-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-7">
                <h3 class="additional-headline">Preise SilverDAT 3 Expert</h3>
                <h1 id="pricing-intro-headline">Was kostet SilverDAT 3 für Kfz-Sachverständige?</h1>
                <p id="pricing-intro-tagline">
                    Die Software der Deutschen Automobil Treuhand (DAT) verbindet eine
                    <br class="d-none d-md-block" />
                    einzigartige Datenbasis mit günstigen Preisen. Und ist damit
                    <br class="d-none d-md-block" />
                    das ideale Kalkulations-Tool für jeden Sachverständigen.
                </p>
            </div>
        </div>
    </div>
</section>

<div>
    <!--============================================-->
    <!-- Price Calculator -->
    <!--============================================-->
    <section id="plans-section">
        <div class="container">
            <div class="subscription-plans-container row justify-content-center">
                <div id="calculator-inputs-column" class="col-md mb-5 mb-md-0">
                    <!--============================================-->
                    <!-- Calculator Inputs -->
                    <!--============================================-->
                    <div id="price-calculator-inputs-wrapper">
                        <a id="price-calculator-dat-logo" href="https://www.dat.de" target="_blank" rel="noopener">
                            <img src="/assets/images/logos/dat-logo-no-text.svg" alt="Logo DAT Deutschland" />
                        </a>
                        <div id="price-calculator-inputs-container">
                            <div class="price-calculator-input-container">
                                <label for="reports-per-month">Kalkulationen pro Monat</label>
                                <input
                                    type="text"
                                    id="reports-per-month"
                                    [(ngModel)]="reportsPerMonth"
                                    placeholder="Bitte ausfüllen"
                                    (input)="initializeTooltips()"
                                    (keydown)="handleArrowUpAndDownKeys($event, 'reportsPerMonth')" />
                            </div>
                            <div class="price-calculator-input-container">
                                <label for="valuations-per-month">Bewertungen pro Monat</label>
                                <input
                                    type="text"
                                    id="valuations-per-month"
                                    [(ngModel)]="valuationsPerMonth"
                                    placeholder="Bitte ausfüllen"
                                    (input)="initializeTooltips()"
                                    (keydown)="handleArrowUpAndDownKeys($event, 'valuationsPerMonth')" />
                            </div>
                            <div class="price-calculator-checkboxes-container">
                                <div class="price-calculator-checkbox-container">
                                    <input
                                        type="checkbox"
                                        id="more-than-one-workstation"
                                        [(ngModel)]="includeRentalCarPricingService"
                                        (click)="initializeTooltips()" />
                                    <label for="more-than-one-workstation">Mietwagenspiegel</label>
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="Nutzungsausfall mit nur einem Klick abfragen.">
                                        i
                                    </span>
                                </div>
                                <div class="price-calculator-checkbox-container">
                                    <input type="checkbox" id="already-dat-customer" [(ngModel)]="alreadyDatCustomer" />
                                    <label for="already-dat-customer">Bereits DAT-Kunde</label>
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="Für Neukunden mit geringem Auftragsvolumen bietet die DAT ein Einsteigerpaket als Starthilfe an.">
                                        i
                                    </span>
                                </div>
                                <div id="price-calculator-number-of-users-container">
                                    <div id="price-calculator-number-of-users-minus" (click)="decreaseUserCount()">
                                        <i class="material-icons">remove</i>
                                    </div>
                                    <div id="price-calculator-number-of-users">{{ numberOfUsers }} Nutzer</div>
                                    <div id="price-calculator-number-of-users-plus" (click)="increaseUserCount()">
                                        <i class="material-icons">add</i>
                                    </div>
                                </div>
                            </div>

                            @if (parseNumber(valuationsPerMonth) > parseNumber(reportsPerMonth)) {
                                <!-- prettier-ignore -->
                                <div class="info-message-within-input-container">
                                    Mehr Bewertungen als Kalkulationen? Dann könnte der
                                    valuateExpert-Tarif interessant sein.
                                    <a
                                        class="smooth-scrolling-link"
                                        href="#get-in-touch-with-dat-section">
                                        Kontakt zum DAT-Vertrieb</a>.
                                </div>
                            }
                            @if (parseNumber(reportsPerMonth) > 120 || parseNumber(valuationsPerMonth) > 120) {
                                <!-- prettier-ignore -->
                                <div class="info-message-within-input-container">
                                    Für große Büros könnte sich die Buchung mehrerer Pauschalen
                                    lohnen.
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="Preise und Freitransaktionen addieren sich.">
                                      i
                                    </span>
                                    <br/>
                                    <a
                                        class="smooth-scrolling-link"
                                        href="#get-in-touch-with-dat-section">
                                        Kontakt zum DAT-Vertrieb</a>.
                                </div>
                            }

                            <div id="in-cooperation-with-autoixpert-container">
                                in Kooperation mit
                                <a href="/">
                                    <img
                                        id="in-cooperation-with-autoixpert-logo"
                                        src="/assets/images/logo-autoixpert-invertiert-blaues-x.svg"
                                        alt="autoiXpert-Logo Software für Sachverständige & Gutachter" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Calculator Inputs -->
                    <!--============================================-->
                </div>

                @if (!calculationInputsComplete()) {
                    <div class="col-md d-flex align-items-center justify-content-center text-center">
                        <div>
                            <h3>
                                Berechne die Kosten für deinen
                                <br class="d-none d-md-block" />
                                Zugang zu DAT SilverDAT 3
                            </h3>
                            <p>Fülle alle Felder im blauen Kasten aus.</p>
                        </div>
                    </div>
                } @else {
                    <!--============================================-->
                    <!-- Per-Case Package -->
                    <!--============================================-->
                    <div class="col-md">
                        <div
                            class="dat-plan-container"
                            [ngClass]="{
                                monthly: paymentCycleMonthly,
                                annually: !paymentCycleMonthly,
                                'best-plan': isBestValuePlan('perCase'),
                                'not-available': !perCasePlanAvailable(
                                    alreadyDatCustomer,
                                    reportsPerMonth,
                                    valuationsPerMonth
                                )
                            }">
                            <h2 class="dat-plan-title">
                                Abrufe
                                <span
                                    class="help-indicator fancy-tooltip"
                                    data-tooltip-content="#per-case-plan-name-tooltip">
                                    i
                                </span>
                            </h2>

                            <!--********** Included Items **********-->
                            <div class="dat-included-items-container">
                                <div class="dat-plan-feature dat-plan-feature-section-heading">Inklusive</div>

                                @if (!alreadyDatCustomer) {
                                    <div class="dat-plan-feature">
                                        Basislizenz
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#dat-license-tooltip">
                                            i
                                        </span>
                                    </div>
                                }
                                <div class="dat-plan-feature">
                                    {{ ANNUAL_CALLS_INCLUDED_PER_CASE }} Transaktionen
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#per-case-transactions-tooltip">
                                        i
                                    </span>
                                </div>
                                <div class="dat-plan-feature">&nbsp;</div>
                                @if (includeRentalCarPricingService) {
                                    <div class="dat-plan-feature">&nbsp;</div>
                                }
                            </div>

                            <!--********** Usage Based Pricing **********-->
                            <div class="usage-based-pricing-container">
                                <div class="dat-plan-feature dat-plan-feature-section-heading">Nach Verbrauch</div>

                                <div class="dat-plan-feature">
                                    VIN-Abfrage: {{ formatPrice(VIN_CALL_PRICE) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="Eine VIN-Abfrage ist der schnellste Weg zur Fahrzeugidentifikation.">
                                        i
                                    </span>
                                </div>

                                <div class="dat-plan-feature">
                                    Kalkulation: {{ formatPrice(CALL_PRICE_PER_CASE_CALCULATION) }}
                                    <span class="help-indicator fancy-tooltip" title="Reparaturkostenkalkulation">
                                        i
                                    </span>
                                </div>

                                <div class="dat-plan-feature">
                                    Bewertung: {{ formatPrice(CALL_PRICE_PER_CASE_VALUATION) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title='Gilt für Bewertungen zum "Stand heute". Für die Kosten von Stichtagsbewertungen s. Bestellformulare der DAT.'>
                                        i
                                    </span>
                                </div>

                                @if (includeRentalCarPricingService) {
                                    <div class="dat-plan-feature">
                                        Mietwagenspiegel: 2,90 €
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#rental-car-pricing-per-case-tooltip">
                                                i
                                        </span>
                                   </div>
                                }
                            </div>

                            <div class="dat-plan-prices-container">
                                <!--============================================-->
                                <!-- Monthly Price -->
                                <!--============================================-->
                                <div class="dat-plan-price-container monthly">
                                    <!--********** 1st Year **********-->
                                    @if (
                                        perCasePlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)
                                    ) {
                                        <div class="dat-plan-price dat-emphasized-price">
                                            {{
                                                formatPrice(
                                                    perCaseFirstYearMonthlyFee(
                                                        numberOfUsers,
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#per-case-price-first-year-monthly-tooltip">
                                                i
                                            </span>
                                        </div>
                                    } @else {
                                        <div class="dat-plan-price price-not-available">
                                            Nicht erhältlich
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                title="Das Abrufmodell kann nur bei sehr geringen Mengen gebucht werden.">
                                                i
                                            </span>
                                        </div>
                                    }
                                    <div class="dat-plan-billing-period">pro Monat im 1. Jahr</div>

                                    <!--********** 2nd Year **********-->
                                    @if (
                                        perCasePlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)
                                    ) {
                                        <div class="dat-plan-price">
                                            {{
                                                formatPrice(
                                                    perCaseMonthlyTotal(
                                                        numberOfUsers,
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#per-case-price-monthly-from-second-year-tooltip">
                                                i
                                            </span>
                                        </div>
                                    } @else {
                                        <div class="dat-plan-price price-not-available">
                                            Nicht erhältlich
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                title="Das Abrufmodell kann nur bei sehr geringen Mengen gebucht werden.">
                                                i
                                            </span>
                                        </div>
                                    }
                                    <div class="dat-plan-billing-period">pro Monat ab 2. Jahr</div>
                                </div>
                                <!--============================================-->
                                <!-- END Monthly Price -->
                                <!--============================================-->

                                <!--============================================-->
                                <!-- Annual Price -->
                                <!--============================================-->
                                <div class="dat-plan-price-container annually">
                                    <!--********** 1st Year **********-->
                                    @if (
                                        perCasePlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)
                                    ) {
                                        <div class="dat-plan-price dat-emphasized-price">
                                            {{
                                                formatPrice(
                                                    perCaseFirstYearFee(
                                                        numberOfUsers,
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#per-case-price-first-year-annually-tooltip">
                                                i
                                            </span>
                                        </div>
                                    } @else {
                                        <div class="dat-plan-price price-not-available">
                                            Nicht erhältlich
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                title="Das Abrufmodell kann nur bei sehr geringen Mengen gebucht werden.">
                                                i
                                            </span>
                                        </div>
                                    }
                                    <div class="dat-plan-billing-period">pro Jahr im 1. Jahr</div>

                                    <!--********** 2nd Year **********-->
                                    @if (
                                        perCasePlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)
                                    ) {
                                        <div class="dat-plan-price">
                                            {{
                                                formatPrice(
                                                    perCaseAnnualTotal(
                                                        numberOfUsers,
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#per-case-price-annually-from-second-year-tooltip">
                                                i
                                            </span>
                                        </div>
                                    } @else {
                                        <div class="dat-plan-price price-not-available">
                                            Nicht erhältlich
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                title="Das Abrufmodell kann nur bei sehr geringen Mengen gebucht werden.">
                                                i
                                            </span>
                                        </div>
                                    }
                                    <div class="dat-plan-billing-period">pro Jahr ab 2. Jahr</div>
                                </div>
                                <!--============================================-->
                                <!-- END Annual Price -->
                                <!--============================================-->
                            </div>

                            @if ((reportsPerMonth || valuationsPerMonth) && isBestValuePlan('perCase')) {
                                <div class="best-plan-container" @fadeInAndOut>Bester Tarif</div>
                            }
                            @if (
                                (reportsPerMonth || valuationsPerMonth) &&
                                !perCasePlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)
                            ) {
                                <div class="starter-plan-not-available-badge" @fadeInAndOut>
                                    Nur für Einsteiger
                                    <span class="help-indicator fancy-tooltip" title="max. 10 Transaktionen">i</span>
                                </div>
                            }
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Per-Case Package -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Starter Package -->
                    <!--============================================-->
                    <div class="col-md">
                        <div
                            class="dat-plan-container"
                            [ngClass]="{
                                monthly: paymentCycleMonthly,
                                annually: !paymentCycleMonthly,
                                'best-plan': isBestValuePlan('starter'),
                                'not-available': !starterPlanAvailable(
                                    alreadyDatCustomer,
                                    reportsPerMonth,
                                    valuationsPerMonth
                                )
                            }">
                            <h2 class="dat-plan-title">
                                Einsteiger
                                <span
                                    class="help-indicator fancy-tooltip"
                                    data-tooltip-content="#starter-plan-name-tooltip">
                                    i
                                </span>
                            </h2>

                            <!--********** Included Items **********-->
                            <div class="dat-included-items-container">
                                <div class="dat-plan-feature dat-plan-feature-section-heading">Inklusive</div>

                                @if (!alreadyDatCustomer) {
                                    <div class="dat-plan-feature">
                                        Basislizenz
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#dat-license-tooltip">
                                            i
                                        </span>
                                    </div>
                                }
                                <!--                                <div class="dat-plan-feature">-->
                                <!--                                    Einweisung-->
                                <!--                                    <span-->
                                <!--                                        class="help-indicator fancy-tooltip"-->
                                <!--                                        data-tooltip-content="#dat-setup-and-instruction-tooltip">-->
                                <!--                                        i-->
                                <!--                                    </span>-->
                                <!--                                </div>-->
                                <div class="dat-plan-feature">
                                    {{ ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION }} Transaktionen
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#starter-plan-transactions-tooltip">
                                        i
                                    </span>
                                </div>
                                <div class="dat-plan-feature">&nbsp;</div>
                                @if (includeRentalCarPricingService) {
                                    <div class="dat-plan-feature">
                                        Mietwagenspiegel
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#rental-car-pricing-flat-fee-tooltip">
                                            i
                                        </span>
                                    </div>
                                }
                            </div>

                            <!--********** Usage Based Pricing **********-->
                            <div class="usage-based-pricing-container">
                                <div class="dat-plan-feature dat-plan-feature-section-heading">Nach Verbrauch</div>

                                <div class="dat-plan-feature">
                                    VIN-Abfrage: {{ formatPrice(VIN_CALL_PRICE) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="Eine VIN-Abfrage ist der schnellste Weg zur Fahrzeugidentifikation.">
                                        i
                                    </span>
                                </div>

                                <div class="dat-plan-feature">
                                    Kalkulation: {{ formatPrice(CALL_PRICE_STARTER_CALCULATION) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="pro Reparaturkostenkalkulation, die über die Inklusiv-Transaktionen hinausgeht. Sobald die Transaktionen aufgebraucht sind, wechselst du automatisch in den Basis-Tarif.">
                                        i
                                    </span>
                                </div>

                                <div class="dat-plan-feature">
                                    Bewertung: {{ formatPrice(CALL_PRICE_STARTER_VALUATION) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title='Gilt für Bewertungen nach Aufbrauch des Freikontingents und zum "Stand heute". Für die Kosten von Stichtagsbewertungen s. Bestellformulare der DAT.'>
                                        i
                                    </span>
                                </div>
                                <div class="dat-plan-feature">&nbsp;</div>
                            </div>

                            <div class="dat-plan-prices-container">
                                <!--============================================-->
                                <!-- Monthly -->
                                <!--============================================-->
                                <div class="dat-plan-price-container monthly">
                                    <!--********** 1st Year **********-->
                                    @if (
                                        starterPlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)
                                    ) {
                                        <div class="dat-plan-price dat-emphasized-price">
                                            {{
                                                formatPrice(
                                                    starterFirstYearMonthlyFee(
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#starter-price-first-year-monthly-tooltip">
                                                i
                                            </span>
                                        </div>
                                    } @else {
                                        <div class="dat-plan-price price-not-available">Nicht erhältlich</div>
                                    }
                                    <div class="dat-plan-billing-period">pro Monat im 1. Jahr</div>

                                    <!--********** 2nd Year **********-->
                                    <div class="dat-plan-price price-not-available">
                                        Nur 1 Jahr buchbar
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            title="Das Einsteigerpaket kann nur einmalig gebucht werden. Danach wechselst du in den Basis- oder Pauschal-Tarif. Dort gilt der Preis 'ab 2. Jahr'.">
                                            i
                                        </span>
                                    </div>
                                    <div class="dat-plan-billing-period">pro Monat ab 2. Jahr</div>
                                </div>
                                <!--============================================-->
                                <!-- END Monthly -->
                                <!--============================================-->

                                <!--============================================-->
                                <!-- Annually -->
                                <!--============================================-->
                                <div class="dat-plan-price-container annually">
                                    @if (
                                        starterPlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)
                                    ) {
                                        <div class="dat-plan-price dat-emphasized-price">
                                            {{
                                                formatPrice(
                                                    starterFirstYearFee(
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#starter-price-first-year-annually-tooltip">
                                                i
                                            </span>
                                        </div>
                                    } @else {
                                        <div class="dat-plan-price dat-emphasized-price price-not-available">
                                            Nicht erhältlich
                                        </div>
                                    }

                                    <div class="dat-plan-billing-period">pro Jahr im 1. Jahr</div>
                                    <div class="dat-plan-price price-not-available">
                                        Nicht erhältlich
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            title="Das Einsteigerpaket kann nur einmalig gebucht werden. Danach wechselst du in den Basis- oder Pauschal-Tarif. Dort gilt der Preis 'ab 2. Jahr'.">
                                            i
                                        </span>
                                    </div>
                                    <div class="dat-plan-billing-period">pro Jahr ab 2. Jahr</div>
                                </div>
                                <!--============================================-->
                                <!-- END Annually -->
                                <!--============================================-->
                            </div>

                            @if ((reportsPerMonth || valuationsPerMonth) && isBestValuePlan('starter')) {
                                <div class="best-plan-container" @fadeInAndOut>Bester Tarif</div>
                            }
                            @if (
                                (reportsPerMonth || valuationsPerMonth) &&
                                !starterPlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)
                            ) {
                                <div class="starter-plan-not-available-badge" @fadeInAndOut>
                                    Nur für Einsteiger
                                    <span class="help-indicator fancy-tooltip" title="max. 15 Transaktionen">i</span>
                                </div>
                            }
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Starter Package -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Basic Plan -->
                    <!--============================================-->
                    <div class="col-md">
                        <div
                            class="dat-plan-container"
                            [ngClass]="{
                                monthly: paymentCycleMonthly,
                                annually: !paymentCycleMonthly,
                                'best-plan': isBestValuePlan('basic')
                            }">
                            <h2 class="dat-plan-title">
                                Basis
                                <span
                                    class="help-indicator fancy-tooltip"
                                    data-tooltip-content="#basic-plan-name-tooltip">
                                    i
                                </span>
                            </h2>

                            <!--********** Included Items **********-->
                            <div class="dat-included-items-container">
                                <div class="dat-plan-feature dat-plan-feature-section-heading">Inklusive</div>

                                @if (!alreadyDatCustomer) {
                                    <div class="dat-plan-feature">
                                        Basislizenz
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#dat-license-tooltip">
                                            i
                                        </span>
                                    </div>
                                }
                                <!--                                <div class="dat-plan-feature">-->
                                <!--                                    Einweisung-->
                                <!--                                    <span-->
                                <!--                                        class="help-indicator fancy-tooltip"-->
                                <!--                                        data-tooltip-content="#dat-setup-and-instruction-tooltip">-->
                                <!--                                        i-->
                                <!--                                    </span>-->
                                <!--                                </div>-->
                                <div class="dat-plan-feature multiple-options monthly">
                                    {{ ANNUAL_CALLS_INCLUDED_BASIC_CALCULATION }} Kalkulationen
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#basic-tooltip-calculations-per-year">
                                        i
                                    </span>
                                </div>
                                <div class="dat-plan-feature">
                                    {{ USERS_INCLUDED + additionalUsers(numberOfUsers) }} Nutzer
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#additional-users-tooltip">
                                        i
                                    </span>
                                </div>
                                @if (includeRentalCarPricingService) {
                                    <div class="dat-plan-feature">
                                        Mietwagenspiegel
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#rental-car-pricing-flat-fee-tooltip">
                                            i
                                        </span>
                                    </div>
                                }
                            </div>

                            <!--********** Usage Based Pricing **********-->
                            <div class="usage-based-pricing-container">
                                <div class="dat-plan-feature dat-plan-feature-section-heading">Nach Verbrauch</div>

                                <div class="dat-plan-feature">
                                    VIN-Abfrage: {{ formatPrice(VIN_CALL_PRICE) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#vin-request-tooltip">
                                        i
                                    </span>
                                </div>

                                <div class="dat-plan-feature">
                                    Kalkulation: {{ formatPrice(CALL_PRICE_BASIC_CALCULATION) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#basic-additional-calculation-fee-tooltip">
                                        i
                                    </span>
                                </div>

                                <div class="dat-plan-feature">
                                    Bewertung: {{ formatPrice(CALL_PRICE_BASIC_VALUATION) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title='Gilt für Bewertungen nach Aufbrauch des Freikontingents und zum "Stand heute". Für die Kosten von Stichtagsbewertungen s. Bestellformulare der DAT.'>
                                        i
                                    </span>
                                </div>
                                <div class="dat-plan-feature">&nbsp;</div>
                            </div>

                            <div class="dat-plan-prices-container">
                                <!--============================================-->
                                <!-- Monthly -->
                                <!--============================================-->
                                <div class="dat-plan-price-container monthly">
                                    @if (!alreadyDatCustomer) {
                                        <!--********** 1st Year **********-->
                                        <div class="dat-plan-price dat-emphasized-price">
                                            {{
                                                formatPrice(
                                                    basicFirstYearMonthlyFee(
                                                        numberOfUsers,
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#basic-price-first-year-monthly-tooltip">
                                                i
                                            </span>
                                        </div>
                                        <div class="dat-plan-billing-period">pro Monat im 1. Jahr</div>
                                    }

                                    <!--********** 2nd Year **********-->
                                    <div
                                        class="dat-plan-price"
                                        [ngClass]="{ 'dat-emphasized-price': alreadyDatCustomer }">
                                        {{
                                            formatPrice(
                                                basicMonthlyTotal(
                                                    numberOfUsers,
                                                    reportsPerMonth,
                                                    valuationsPerMonth,
                                                    includeRentalCarPricingService
                                                ),
                                                0
                                            )
                                        }}
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#basic-price-monthly-from-second-year-tooltip">
                                            i
                                        </span>
                                    </div>
                                    <div class="dat-plan-billing-period">pro Monat ab 2. Jahr</div>
                                </div>
                                <!--============================================-->
                                <!-- END Monthly -->
                                <!--============================================-->

                                <!--============================================-->
                                <!-- Annually -->
                                <!--============================================-->
                                <div class="dat-plan-price-container annually">
                                    @if (!alreadyDatCustomer) {
                                        <!--********** 1st Year **********-->
                                        <div class="dat-plan-price dat-emphasized-price">
                                            {{
                                                formatPrice(
                                                    basicFirstYearFee(
                                                        numberOfUsers,
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#basic-price-first-year-annually-tooltip">
                                                i
                                            </span>
                                        </div>
                                        <div class="dat-plan-billing-period">pro Jahr im 1. Jahr</div>
                                    }
                                    <!--********** 2nd Year **********-->
                                    <div
                                        class="dat-plan-price"
                                        [ngClass]="{ 'dat-emphasized-price': alreadyDatCustomer }">
                                        {{
                                            formatPrice(
                                                basicAnnualTotal(
                                                    numberOfUsers,
                                                    reportsPerMonth,
                                                    valuationsPerMonth,
                                                    includeRentalCarPricingService
                                                ),
                                                0
                                            )
                                        }}

                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#basic-price-annually-from-second-year-tooltip">
                                            i
                                        </span>
                                    </div>
                                    <div class="dat-plan-billing-period">pro Jahr ab 2. Jahr</div>
                                </div>
                                <!--============================================-->
                                <!-- END Annually -->
                                <!--============================================-->
                            </div>

                            @if ((reportsPerMonth || valuationsPerMonth) && isBestValuePlan('basic')) {
                                <div class="best-plan-container" @fadeInAndOut>Bester Tarif</div>
                            }
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Basic Plan -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Flatrate Plan -->
                    <!--============================================-->
                    <div class="col-md">
                        <div
                            class="dat-plan-container"
                            [ngClass]="{
                                monthly: paymentCycleMonthly,
                                annually: !paymentCycleMonthly,
                                'best-plan': isBestValuePlan('flatrate')
                            }">
                            <h2 class="dat-plan-title">
                                Pauschale
                                <span
                                    class="help-indicator fancy-tooltip"
                                    data-tooltip-content="#flatrate-plan-name-tooltip">
                                    i
                                </span>
                            </h2>

                            <!--********** Included Items **********-->
                            <div class="dat-included-items-container">
                                <div class="dat-plan-feature dat-plan-feature-section-heading">Inklusive</div>

                                @if (!alreadyDatCustomer) {
                                    <div class="dat-plan-feature">
                                        Basislizenz
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#dat-license-tooltip">
                                            i
                                        </span>
                                    </div>
                                }
                                <!--                                <div class="dat-plan-feature">-->
                                <!--                                    Einweisung-->
                                <!--                                    <span-->
                                <!--                                        class="help-indicator fancy-tooltip"-->
                                <!--                                        data-tooltip-content="#dat-setup-and-instruction-tooltip">-->
                                <!--                                        i-->
                                <!--                                    </span>-->
                                <!--                                </div>-->
                                <div class="dat-plan-feature multiple-options monthly">
                                    {{ ANNUAL_CALLS_INCLUDED_FLATRATE }} Transaktionen
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#flatrate-plan-transaction-tooltip">
                                        i
                                    </span>
                                </div>
                                <div class="dat-plan-feature">
                                    {{ USERS_INCLUDED + additionalUsers(numberOfUsers) }} Nutzer
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#additional-users-tooltip">
                                        i
                                    </span>
                                </div>
                                @if (includeRentalCarPricingService) {
                                    <div class="dat-plan-feature">
                                        Mietwagenspiegel
                                        <span
                                          class="help-indicator fancy-tooltip"
                                          data-tooltip-content="#rental-car-pricing-flat-fee-tooltip">
                                                  i
                                        </span>
                                    </div>
                                }
                            </div>

                            <!--********** Usage Based Pricing **********-->
                            <div class="usage-based-pricing-container">
                                <div class="dat-plan-feature dat-plan-feature-section-heading">Nach Verbrauch</div>

                                <div class="dat-plan-feature">
                                    VIN-Abfrage: {{ formatPrice(VIN_CALL_PRICE) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="Eine VIN-Abfrage ist der schnellste Weg zur Fahrzeugidentifikation.">
                                        i
                                    </span>
                                </div>

                                <div class="dat-plan-feature">
                                    Transaktion: ab
                                    {{ formatPrice(lowestApplicableCalculationPrice(reportsPerMonth)) }}
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        data-tooltip-content="#flatrate-plan-usage-based-transactions-tooltip">
                                        i
                                    </span>
                                </div>

                                <div class="dat-plan-feature">&nbsp;</div>
                                <div class="dat-plan-feature">&nbsp;</div>
                            </div>

                            <div class="dat-plan-prices-container">
                                <!--============================================-->
                                <!-- Monthly -->
                                <!--============================================-->
                                <div class="dat-plan-price-container monthly">
                                    @if (!alreadyDatCustomer) {
                                        <!--********** 1st Year **********-->
                                        <div class="dat-plan-price dat-emphasized-price">
                                            {{
                                                formatPrice(
                                                    flatrateFirstYearMonthlyFee(
                                                        numberOfUsers,
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#flatrate-price-first-year-monthly-tooltip">
                                                i
                                            </span>
                                        </div>
                                        <div class="dat-plan-billing-period">pro Monat im 1. Jahr</div>
                                    }

                                    <!--********** 2nd Year **********-->
                                    <div
                                        class="dat-plan-price"
                                        [ngClass]="{ 'dat-emphasized-price': alreadyDatCustomer }">
                                        {{
                                            formatPrice(
                                                flatrateMonthlyTotal(
                                                    numberOfUsers,
                                                    reportsPerMonth,
                                                    valuationsPerMonth,
                                                    includeRentalCarPricingService
                                                ),
                                                0
                                            )
                                        }}
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#flatrate-price-from-second-year-monthly-tooltip">
                                            i
                                        </span>
                                    </div>
                                    <div class="dat-plan-billing-period">pro Monat ab 2. Jahr</div>
                                </div>
                                <!--============================================-->
                                <!-- END Monthly -->
                                <!--============================================-->

                                <!--============================================-->
                                <!-- Annually -->
                                <!--============================================-->
                                <div class="dat-plan-price-container annually">
                                    @if (!alreadyDatCustomer) {
                                        <!--********** 1st Year **********-->
                                        <div class="dat-plan-price dat-emphasized-price">
                                            {{
                                                formatPrice(
                                                    flatrateFirstYearAnnualFee(
                                                        numberOfUsers,
                                                        reportsPerMonth,
                                                        valuationsPerMonth,
                                                        includeRentalCarPricingService
                                                    ),
                                                    0
                                                )
                                            }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#flatrate-price-first-year-annually-tooltip">
                                                i
                                            </span>
                                        </div>
                                        <div class="dat-plan-billing-period">pro Jahr im 1. Jahr</div>
                                    }

                                    <!--********** 2nd Year **********-->
                                    <div
                                        class="dat-plan-price"
                                        [ngClass]="{ 'dat-emphasized-price': alreadyDatCustomer }">
                                        {{
                                            formatPrice(
                                                flatrateAnnualTotal(
                                                    numberOfUsers,
                                                    reportsPerMonth,
                                                    valuationsPerMonth,
                                                    includeRentalCarPricingService
                                                ),
                                                0
                                            )
                                        }}
                                        <span
                                            class="help-indicator fancy-tooltip"
                                            data-tooltip-content="#flatrate-price-from-second-year-annually-tooltip">
                                            i
                                        </span>
                                    </div>
                                    <div class="dat-plan-billing-period">pro Jahr ab 2. Jahr</div>
                                </div>
                                <!--============================================-->
                                <!-- END Annually -->
                                <!--============================================-->
                            </div>
                            @if ((reportsPerMonth || valuationsPerMonth) && isBestValuePlan('flatrate')) {
                                <div class="best-plan-container" @fadeInAndOut>Bester Tarif</div>
                            }
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Flatrate Plan -->
                    <!--============================================-->
                }
            </div>
        </div>
    </section>

    @if (calculationInputsComplete()) {
        <section id="payment-period-section">
            <div class="container">
                <div class="row mb-5">
                    <div id="payment-cycle-note" class="col-sm text-center">
                        Alle Preise netto.
                        <br />
                        Vertragslaufzeit bis Ende des nächsten Kalenderjahres.
                        <br />
                        Weitere Details auf den allein gültigen Bestellformularen der DAT.
                    </div>
                </div>
                <div class="row">
                    <div id="billing-period-container" class="col-md-12">
                        <div id="billing-period-title">Zahlweise</div>
                        <div id="billing-period-selection-container">
                            <div
                                id="billing-period-label-monthly"
                                [ngClass]="{ active: paymentCycleMonthly }"
                                (click)="setPaymentToMonthly()">
                                Monatlich
                            </div>
                            <div id="billing-period-switch-background" (click)="toggleMonthlyAndAnnually($event)">
                                <div
                                    id="billing-period-switch"
                                    [ngClass]="{
                                        monthly: paymentCycleMonthly,
                                        annually: !paymentCycleMonthly
                                    }">
                                    &nbsp;
                                </div>
                            </div>
                            <div
                                id="billing-period-label-annually"
                                [ngClass]="{ active: !paymentCycleMonthly }"
                                (click)="setPaymentToAnnually()">
                                Jährlich
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }

    <section id="get-in-touch-with-dat-section">
        <div class="container">
            @if (!contactFormSent) {
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h2 id="get-in-touch-with-dat-heading">Kontakt zum DAT-Vertrieb</h2>
                        <p id="get-in-touch-with-dat-intro">
                            Lass dich vom DAT-Vertrieb kontaktieren, der gemeinsam mit dir die passenden Konditionen
                            ermittelt.
                        </p>
                    </div>
                </div>
                <form class="row justify-content-center" (submit)="sendContactForm(); $event.preventDefault()">
                    <div class="col-md-5">
                        <div class="contact-form-input-container">
                            <input
                                type="text"
                                class="newsletter-input"
                                id="dat-get-in-touch-organization-input"
                                placeholder="Firma"
                                required
                                [(ngModel)]="organization"
                                [ngModelOptions]="{ standalone: true }" />
                        </div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                type="text"
                                class="newsletter-input"
                                id="dat-get-in-touch-first-name-input"
                                placeholder="Vorname"
                                required
                                [(ngModel)]="firstName"
                                [ngModelOptions]="{ standalone: true }" />
                            <input
                                type="text"
                                class="newsletter-input"
                                id="dat-get-in-touch-last-name-input"
                                placeholder="Nachname"
                                required
                                [(ngModel)]="lastName"
                                [ngModelOptions]="{ standalone: true }" />
                        </div>
                        <div class="contact-form-input-container"></div>
                        <div class="contact-form-input-container">
                            <input
                                type="text"
                                class="newsletter-input"
                                id="dat-get-in-touch-street-input"
                                placeholder="Straße und Hausnummer"
                                required
                                [(ngModel)]="streetAndHouseNumber"
                                [ngModelOptions]="{ standalone: true }" />
                        </div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                type="text"
                                class="newsletter-input"
                                id="dat-get-in-touch-zip-input"
                                placeholder="PLZ"
                                required
                                [(ngModel)]="zip"
                                [ngModelOptions]="{ standalone: true }"
                                (input)="getCityByZip()" />
                            <input
                                type="text"
                                class="newsletter-input"
                                id="dat-get-in-touch-city-input"
                                placeholder="Ort"
                                required
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="city" />
                        </div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                type="email"
                                class="newsletter-input"
                                id="dat-get-in-touch-email-input"
                                placeholder="E-Mail"
                                required
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="email" />
                            <input
                                type="tel"
                                class="newsletter-input"
                                id="dat-get-in-touch-phone-input"
                                placeholder="Telefon"
                                required
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="phone" />
                        </div>
                        <div id="transmit-usage-data-checkbox-container" class="text-center">
                            <input
                                type="checkbox"
                                id="transmit-usage-data"
                                name="transmit-usage-data"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="submitUsageData" />
                            <label for="transmit-usage-data">Daten des Preisrechners übermitteln</label>
                        </div>
                        <div class="contact-form-input-container">
                            <button
                                type="submit"
                                id="dat-get-in-touch-submit-button"
                                class="newsletter-input primary-button"
                                [title]="datContactFormComplete() ? '' : 'Bitte Kontaktdaten angeben'">
                                @if (contactFormSending) {
                                    <img
                                        class="pending-indicator"
                                        src="/assets/images/loading-indicator-white.svg"
                                        alt="" />
                                } @else {
                                    <span>Kontaktanfrage senden</span>
                                }
                            </button>
                        </div>
                    </div>
                </form>
                <div class="row justify-content-center text-center" id="dat-contact-form-legal-info">
                    <div class="col-md-6 label">
                        <p>
                            Greifst du lieber zum Hörer und sprichst persönlich mit jemandem?
                            <br />
                            Hotline:
                            <a href="tel:+49 711 4503 140">+49 711 4503 140</a>
                            <br />
                            <a href="https://www.dat.de/service/ansprechpartner/" target="_blank" rel="noopener">
                                Persönlichen DAT-Vertriebler finden
                            </a>
                        </p>

                        <!-- prettier-ignore -->
                        <p>
                            Deine Kontakdaten werden ausschließlich an uns (autoiXpert) und an
                            die Deutsche Automobil Treuhand übermittelt.
                            <a href="/datenschutz" target="_blank" rel="noopener">Infos zum Datenschutz</a>.
                            Die Preise auf dieser Seite sind ein unverbindliches Hilfsmittel,
                            es gelten stets die offiziellen Preislisten der DAT.
                        </p>
                        <p>
                            Dieser DAT-Preisrechner wird von
                            <a href="/">autoiXpert</a>
                            zur Verfügung gestellt, einem zertifizierten Technologie-Partner der DAT.
                        </p>
                    </div>
                </div>
            }
            @if (contactFormSent) {
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h2>Anfrage gesendet</h2>
                        <p>
                            Vielen Dank, deine Anfrage wurde an das Vertriebsteam der Deutschen Automobil Treuhand
                            weitergeleitet.
                        </p>
                    </div>
                </div>
            }
        </div>
        <div id="newsletter-signup-response-container" class="container"></div>
    </section>

    <section id="starter-plan-section">
        <div class="container">
            <div class="row">
                <div class="col-md text-center">
                    <h2 id="starter-plan-section-heading">DAT-Einsteigerpaket für Neukunden</h2>
                    <p id="starter-plan-section-intro">
                        Für
                        <strong>1.680 €</strong>
                        kannst du SilverDAT 3 mit
                        {{ ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION }}
                        Kalkulationen und
                        <br class="d-none d-md-block" />
                        {{ ANNUAL_CALLS_INCLUDED_STARTER_VALUATION }} Fahrzeugbewertungen erwerben.
                    </p>
                </div>
            </div>
            <div class="row text-center" id="starter-plan-items-row">
                <div class="col-md-3">
                    <div class="included-item-container">
                        <img src="/assets/images/license.png" alt="DAT Basislizenz Icon" class="included-item-icon" />
                        <h3 class="included-item-heading">Einrichtung & Basislizenz</h3>
                        <p class="included-item-text">im Wert von 1.420 €</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="included-item-container">
                        <img
                            src="/assets/images/calculator.png"
                            alt="Kalkulations-Taschenrechner Icon"
                            class="included-item-icon" />
                        <h3 class="included-item-heading">
                            {{ ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION }} Kalkulationen
                        </h3>
                        <p class="included-item-text">
                            mit SilverDAT 3
                            <br />
                            im Wert von
                            {{
                                formatPrice(ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION * CALL_PRICE_STARTER_CALCULATION)
                            }}
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="included-item-container">
                        <img src="/assets/images/car-outline.png" alt="Bewertungs-Auto" class="included-item-icon" />
                        <h3 class="included-item-heading">{{ ANNUAL_CALLS_INCLUDED_STARTER_VALUATION }} Bewertungen</h3>
                        <p class="included-item-text">
                            mit SilverDAT 3
                            <br />
                            im Wert von
                            {{ formatPrice(ANNUAL_CALLS_INCLUDED_STARTER_VALUATION * CALL_PRICE_STARTER_VALUATION) }}
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="included-item-container">
                        <img
                            src="/assets/images/1680.png"
                            alt="DAT Starter-Tarif Gesamtpreis"
                            class="included-item-icon" />
                        <h3 class="included-item-heading">1.680 € Gesamtpreis</h3>
                        <p class="included-item-text">
                            für Bestandteile im Wert von
                            <br />
                            {{ formatPrice(starterPackageEquivalentValue()) }}. Du sparst
                            {{ roundNumber(starterPackageSavingsInPercent() * 100) }}
                            %.
                        </p>
                    </div>
                </div>
            </div>

            <!--**********
        According to Stefan Krone (potential customer, https://autoixpert.pipedrive.com/deal/2203), the DAT sales representative reacted a little offended when Mr. Krone
        told him about this slogan on our homepage.
        Mr. Krone was unsure who his sales representative was but the automatic email from the "DAT Preisrechner" was sent to Torben Nimke.
        **********-->
            <!--            <div class="row mb-3">-->
            <!--                <div class="col-md text-center">-->
            <!--                    <p>-->
            <!--                        <strong>Ganz junge Büros aufgepasst</strong>: Sprich den DAT-Vertrieb auf besondere Angebote mit noch geringerer Grundgebühr an.-->
            <!--                    </p>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div id="features-link-container" class="exclamation-box-container">
                        <h3 class="exclamation-box-heading">Alles, was du als Gutachter brauchst</h3>
                        <div>Textbausteine, Kalkulationen, Buchhaltung & mehr.</div>
                        <a
                            class="exclamation-box-button button-solid light"
                            href="/kfz-sachverstaendigen-software?utm_source=dat-preisrechner&utm_medium=exclamation-box-bottom">
                            autoiXpert entdecken
                        </a>
                        <a class="exclamation-box-button button-solid ghost-button-light" href="/registrierung">
                            Kostenfrei testen
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="tooltip_templates">
        <!--============================================-->
        <!-- Per-Case Tooltips -->
        <!--============================================-->
        <div id="per-case-plan-name-tooltip">
            Der Tarif für Berufseinsteiger. Geringe Grundgebühr + Zahlung pro Vorgang.
            <br />
            <br />
            Monatliche Grundgebühr: {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE) }}
            <br />
            <br />
            Transaktionen werden wie folgt berechnet:
            <br />
            <strong>VIN-Abfrage</strong>
            : {{ formatPrice(VIN_CALL_PRICE) }}
            <br />
            <strong>Kalkulation</strong>
            : {{ formatPrice(CALL_PRICE_PER_CASE_CALCULATION) }}
            <br />
            <strong>Bewertung</strong>
            : {{ formatPrice(CALL_PRICE_PER_CASE_VALUATION) }}
            <br />
        </div>

        <!--********** Rental Car Pricing **********-->
        <div id="rental-car-pricing-per-case-tooltip">
            Ermittlung des Nutzungsausfalls und der Mietwagenpreise.
        </div>

        <!--********** Transactions **********-->
        <div id="per-case-transactions-tooltip">
            Transaktion = Kalkulation + Bewertung innerhalb eines Vorgangs.
            <br />
            <br />
            {{ ANNUAL_CALLS_INCLUDED_PER_CASE }} Transaktionen im Jahr entsprechen im Schnitt etwa
            {{ roundNumber(ANNUAL_CALLS_INCLUDED_PER_CASE / 12) }}
            Transaktionen pro Monat.
        </div>

        <!--********** Monthly 1st Year **********-->
        <div id="per-case-price-first-year-monthly-tooltip">
            Monatlicher Preis =
            {{
                formatPrice(
                    perCaseFirstYearMonthlyFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>
                    Ein Zwölftel des Jahrespreises ({{
                        formatPrice(
                            perCaseFirstYearFee(
                                numberOfUsers,
                                reportsPerMonth,
                                valuationsPerMonth,
                                includeRentalCarPricingService
                            ) / 12
                        )
                    }})
                </li>
                <li>Gebühr für monatliche Zahlweise ({{ formatPrice(MONTHLY_PAYMENT_CYCLE_FEE) }})</li>
            </ul>
            Jahrespreis =
            {{
                formatPrice(
                    perCaseFirstYearFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <ul>
                <!--                <li>-->
                <!--                    Einrichtung ({{ formatPrice(DAT_SETUP_WITHOUT_INSTRUCTION) }}) (ohne Einweisung. Stattdessen sind-->
                <!--                    Webinare à-->
                <!--                    {{ formatPrice(WEBINAR_PRICE) }}-->
                <!--                    über dat.de buchbar)-->
                <!--                </li>-->
                <li>
                    Monatl. Grundgebühr (12 *
                    {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE) }} = {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel ({{ perCaseRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR_PER_CASE) }} =
                        {{ formatPrice(perCaseRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (perCaseAdditionalCallsCalculationPrice(reportsPerMonth)) {
                    <li>
                        Zusätzl. Kalkulationen pro Jahr ({{ perCaseAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_PER_CASE_CALCULATION) }} =
                        {{ formatPrice(perCaseAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (perCaseAdditionalCallsValuationPrice(valuationsPerMonth)) {
                    <li>
                        Zusätzl. Bewertungen pro Jahr ({{ perCaseAdditionalCallsValuation(valuationsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_PER_CASE_VALUATION) }} =
                        {{ formatPrice(perCaseAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** Monthly from 2nd Year **********-->
        <div id="per-case-price-monthly-from-second-year-tooltip">
            Monatlicher Preis =
            {{
                formatPrice(
                    perCaseMonthlyTotal(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>
                    Ein Zwölftel des Jahrespreises ({{
                        formatPrice(
                            perCaseAnnualTotal(
                                numberOfUsers,
                                reportsPerMonth,
                                valuationsPerMonth,
                                includeRentalCarPricingService
                            ) / 12
                        )
                    }})
                </li>
                <li>Gebühr für monatliche Zahlweise ({{ formatPrice(MONTHLY_PAYMENT_CYCLE_FEE) }})</li>
            </ul>
            Jahrespreis =
            {{
                formatPrice(
                    perCaseAnnualTotal(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <ul>
                <li>
                    Monatl. Grundgebühr (12 *
                    {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE) }} = {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (perCaseAdditionalCallsCalculation(reportsPerMonth)) {
                    <li>
                        Kalkulationen pro Jahr ({{ perCaseAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_PER_CASE_CALCULATION) }} =
                        {{ formatPrice(perCaseAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (perCaseAdditionalCallsValuation(valuationsPerMonth)) {
                    <li>
                        Bewertungen pro Jahr ({{ parseNumber(valuationsPerMonth) * 12 }} *
                        {{ formatPrice(CALL_PRICE_PER_CASE_VALUATION) }} =
                        {{ formatPrice(perCaseAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** Annually 1st Year **********-->
        <div id="per-case-price-first-year-annually-tooltip">
            Jahrespreis =
            {{
                formatPrice(
                    perCaseFirstYearFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>DAT-Basislizenz ({{ formatPrice(DAT_LICENSE) }})</li>
                <!--                <li>-->
                <!--                    Einrichtung ({{ formatPrice(DAT_SETUP_WITHOUT_INSTRUCTION) }}) (ohne Einweisung. Stattdessen sind-->
                <!--                    Webinare à-->
                <!--                    {{ formatPrice(WEBINAR_PRICE) }}-->
                <!--                    über dat.de buchbar)-->
                <!--                </li>-->
                <li>
                    Monatl. Grundgebühr (12 *
                    {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE) }} = {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (perCaseAdditionalCallsCalculationPrice(reportsPerMonth)) {
                    <li>
                        Kalkulationen pro Jahr ({{ perCaseAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_PER_CASE_CALCULATION) }} =
                        {{ formatPrice(perCaseAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (perCaseAdditionalCallsValuationPrice(valuationsPerMonth)) {
                    <li>
                        Bewertungen pro Jahr ({{ perCaseAdditionalCallsValuation(valuationsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_PER_CASE_VALUATION) }} =
                        {{ formatPrice(perCaseAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** 2nd+ Year Annually **********-->
        <div id="per-case-price-annually-from-second-year-tooltip">
            Jahrespreis =
            {{
                formatPrice(
                    perCaseAnnualTotal(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>
                    Monatl. Grundgebühr (12 *
                    {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE) }} = {{ formatPrice(MONTHLY_BASE_FEE_PER_CASE * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (perCaseAdditionalCallsCalculation(reportsPerMonth)) {
                    <li>
                        Zusätzl. Kalkulationen pro Jahr ({{ perCaseAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_PER_CASE_CALCULATION) }} =
                        {{ formatPrice(perCaseAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (perCaseAdditionalCallsValuation(valuationsPerMonth)) {
                    <li>
                        Bewertungen pro Jahr ({{ parseNumber(valuationsPerMonth) * 12 }} *
                        {{ formatPrice(CALL_PRICE_PER_CASE_VALUATION) }} =
                        {{ formatPrice(perCaseAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--============================================-->
        <!-- END Per-Case Tooltips -->
        <!--============================================-->

        <!--============================================-->
        <!-- Starter Tooltips -->
        <!--============================================-->
        <div id="starter-plan-name-tooltip">
            Das Paket für Neueinsteiger mit bis zu
            {{ MAXIMUM_NUMBER_OF_MONTHLY_CASES_STARTER }}
            Gutachten im Monat.
            <br />
            <br />
            Nach Aufbrauch des Pakets oder nach Ablauf der Vertragslaufzeit von einem Jahr geht der Vertrag in den
            Basistarif über. Ein Wechsel in die Pauschale ist jederzeit möglich.
        </div>

        <div id="starter-plan-transactions-tooltip">
            Transaktion = Kalkulation + Bewertung innerhalb eines Vorgangs.
            <br />
            <br />
            {{ ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION }} Transaktionen im Jahr entsprechen im Schnitt etwa
            {{ roundNumber(ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION / 12) }}
            Transaktionen pro Monat.
        </div>

        <div id="starter-price-first-year-monthly-tooltip">
            Monatlicher Preis =
            {{
                formatPrice(
                    starterFirstYearMonthlyFee(reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)
                )
            }}
            <br />
            <ul>
                <li>
                    Ein Zwölftel des Jahrespreises ({{
                        formatPrice(
                            starterFirstYearFee(reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) /
                                12
                        )
                    }})
                </li>
                <li>Gebühr für monatliche Zahlweise ({{ formatPrice(MONTHLY_PAYMENT_CYCLE_FEE) }})</li>
            </ul>
            Jahrespreis =
            {{ formatPrice(starterFirstYearFee(reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)) }}
            <ul>
                <li>Paketpreis ({{ formatPrice(STARTER_SETUP_FEE) }})</li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (starterAdditionalCallsCalculationPrice(reportsPerMonth)) {
                    <li>
                        Zusätzl. Kalkulationen pro Jahr ({{ starterAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_STARTER_CALCULATION) }} =
                        {{ formatPrice(starterAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (starterAdditionalCallsValuationPrice(valuationsPerMonth)) {
                    <li>
                        Zusätzl. Bewertungen pro Jahr ({{ starterAdditionalCallsValuation(valuationsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_STARTER_VALUATION) }} =
                        {{ formatPrice(starterAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
            </ul>
        </div>
        <div id="starter-price-first-year-annually-tooltip">
            Jahrespreis =
            {{ formatPrice(starterFirstYearFee(reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)) }}
            <br />
            <ul>
                <li>Paketpreis ({{ formatPrice(STARTER_SETUP_FEE) }})</li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (starterAdditionalCallsCalculationPrice(reportsPerMonth)) {
                    <li>
                        Zusätzl. Kalkulationen pro Jahr ({{ starterAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_STARTER_CALCULATION) }} =
                        {{ formatPrice(starterAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (starterAdditionalCallsValuationPrice(valuationsPerMonth)) {
                    <li>
                        Zusätzl. Bewertungen pro Jahr ({{ starterAdditionalCallsValuation(valuationsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_STARTER_VALUATION) }} =
                        {{ formatPrice(starterAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
            </ul>
        </div>
        <!--============================================-->
        <!-- END Starter Tooltips -->
        <!--============================================-->

        <!--============================================-->
        <!-- Basic Tooltips -->
        <!--============================================-->
        <!--********** Plan Name **********-->
        <div id="basic-plan-name-tooltip">
            Der Tarif für kleine Büros.
            <br />
            <br />
            Grundstock an Kalkulationen, erweiterbar nach Verbrauch.
        </div>

        <!--********** Included Calculations per Year **********-->
        <div id="basic-tooltip-calculations-per-year">
            {{ ANNUAL_CALLS_INCLUDED_BASIC_CALCULATION }} Kalkulationen im Jahr sind inklusive und entsprechen im
            Schnitt
            {{ roundNumber(ANNUAL_CALLS_INCLUDED_BASIC_CALCULATION / 12) }}
            Kalkulationen pro Monat.
        </div>

        <!--********** Additional Calculations **********-->
        <div id="basic-additional-calculation-fee-tooltip">
            pro Reparaturkostenkalkulation, die über das Freikontingent hinaus geht.
        </div>

        <!--********** Monthly 1st Year **********-->
        <div id="basic-price-first-year-monthly-tooltip">
            Der Preis im 1. Jahr enthält Einmalgebühren. Ab dem 2. Jahr ist der Preis deshalb geringer.
            <br />
            <br />

            Monatlicher Preis =
            {{
                formatPrice(
                    basicFirstYearMonthlyFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>
                    Ein Zwölftel des Jahrespreises ({{
                        formatPrice(
                            basicFirstYearFee(
                                numberOfUsers,
                                reportsPerMonth,
                                valuationsPerMonth,
                                includeRentalCarPricingService
                            ) / 12
                        )
                    }})
                </li>
                <li>Gebühr für monatliche Zahlweise ({{ formatPrice(MONTHLY_PAYMENT_CYCLE_FEE) }})</li>
            </ul>
            Jahrespreis =
            {{
                formatPrice(
                    basicFirstYearFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <ul>
                <li>Basislizenz ({{ formatPrice(DAT_LICENSE) }})</li>
                <!--                <li>Einweisung ({{ formatPrice(DAT_SETUP_AND_INSTRUCTION) }})</li>-->
                <li>
                    Monatl. Grundgebühr (12 * {{ formatPrice(MONTHLY_BASE_FEE_BASIC) }} =
                    {{ formatPrice(MONTHLY_BASE_FEE_BASIC * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (basicAdditionalCallsCalculation(reportsPerMonth)) {
                    <li>
                        Zusätzl. Kalkulationen pro Jahr ({{ basicAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_BASIC_CALCULATION) }} =
                        {{ formatPrice(basicAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (basicAdditionalCallsValuation(valuationsPerMonth)) {
                    <li>
                        Bewertungen pro Jahr ({{ parseNumber(valuationsPerMonth) * 12 }} *
                        {{ formatPrice(CALL_PRICE_BASIC_VALUATION) }} =
                        {{ formatPrice(basicAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** Monthly from 2nd Year **********-->
        <div id="basic-price-monthly-from-second-year-tooltip">
            Monatlicher Preis =
            {{
                formatPrice(
                    basicMonthlyTotal(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>
                    Ein Zwölftel des Jahrespreises ({{
                        formatPrice(
                            basicAnnualTotal(
                                numberOfUsers,
                                reportsPerMonth,
                                valuationsPerMonth,
                                includeRentalCarPricingService
                            ) / 12
                        )
                    }})
                </li>
                <li>Gebühr für monatliche Zahlweise ({{ formatPrice(MONTHLY_PAYMENT_CYCLE_FEE) }})</li>
            </ul>
            Jahrespreis =
            {{
                formatPrice(
                    basicAnnualTotal(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)
                )
            }}
            <ul>
                <li>
                    Monatl. Grundgebühr (12 * {{ formatPrice(MONTHLY_BASE_FEE_BASIC) }} =
                    {{ formatPrice(MONTHLY_BASE_FEE_BASIC * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (basicAdditionalCallsCalculation(reportsPerMonth)) {
                    <li>
                        Zusätzl. Kalkulationen pro Jahr ({{ basicAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_BASIC_CALCULATION) }} =
                        {{ formatPrice(basicAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (basicAdditionalCallsValuation(valuationsPerMonth)) {
                    <li>
                        Bewertungen pro Jahr ({{ parseNumber(valuationsPerMonth) * 12 }} *
                        {{ formatPrice(CALL_PRICE_BASIC_VALUATION) }} =
                        {{ formatPrice(basicAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** 1st Year Annually **********-->
        <div id="basic-price-first-year-annually-tooltip">
            Der Preis im ersten Jahr ist höher, weil er Einmalgebühren beinhaltet.
            <br />
            <br />

            Jahrespreis =
            {{
                formatPrice(
                    basicFirstYearFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>Basislizenz ({{ formatPrice(DAT_LICENSE) }})</li>
                <!--                <li>Einweisung ({{ formatPrice(DAT_SETUP_AND_INSTRUCTION) }})</li>-->
                <li>
                    Monatl. Grundgebühr (12 * {{ formatPrice(MONTHLY_BASE_FEE_BASIC) }} =
                    {{ formatPrice(MONTHLY_BASE_FEE_BASIC * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (basicAdditionalCallsCalculation(reportsPerMonth)) {
                    <li>
                        Zusätzl. Kalkulationen pro Jahr ({{ basicAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_BASIC_CALCULATION) }} =
                        {{ formatPrice(basicAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (basicAdditionalCallsValuation(valuationsPerMonth)) {
                    <li>
                        Bewertungen pro Jahr ({{ parseNumber(valuationsPerMonth) * 12 }} *
                        {{ formatPrice(CALL_PRICE_BASIC_VALUATION) }} =
                        {{ formatPrice(basicAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** 2nd+ Year Annually **********-->
        <div id="basic-price-annually-from-second-year-tooltip">
            Jahrespreis =
            {{
                formatPrice(
                    basicAnnualTotal(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)
                )
            }}
            <br />
            <ul>
                <li>
                    Monatl. Grundgebühr (12 * {{ formatPrice(MONTHLY_BASE_FEE_BASIC) }} =
                    {{ formatPrice(MONTHLY_BASE_FEE_BASIC * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (basicAdditionalCallsCalculation(reportsPerMonth)) {
                    <li>
                        Zusätzl. Kalkulationen pro Jahr ({{ basicAdditionalCallsCalculation(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_BASIC_CALCULATION) }} =
                        {{ formatPrice(basicAdditionalCallsCalculationPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (basicAdditionalCallsValuation(valuationsPerMonth)) {
                    <li>
                        Bewertungen pro Jahr ({{ parseNumber(valuationsPerMonth) * 12 }} *
                        {{ formatPrice(CALL_PRICE_BASIC_VALUATION) }} =
                        {{ formatPrice(basicAdditionalCallsValuationPrice(valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--============================================-->
        <!-- END Basic Tooltips -->
        <!--============================================-->

        <!--============================================-->
        <!-- Flatrate Tooltips -->
        <!--============================================-->
        <div id="flatrate-plan-name-tooltip">
            Der Tarif für große Büros.
            <br />
            <br />
            Jede Pauschale beinhaltet
            {{ ANNUAL_CALLS_INCLUDED_FLATRATE }} Kalkulationen & {{ ANNUAL_CALLS_INCLUDED_FLATRATE }} Bewertungen im
            Jahr (ca. {{ roundNumber(ANNUAL_CALLS_INCLUDED_FLATRATE / 12) }} pro Monat).
            <br />
            Zusätzliche Transaktionen werden wie folgt berechnet:
            <br />
            <strong>VIN-Abfrage</strong>
            : {{ formatPrice(VIN_CALL_PRICE) }}
            <br />
            <strong>Kalkulation oder Bewertung</strong>
            :
            <br />
            <ul>
                <li>751. - 850.: {{ formatPrice(CALL_PRICE_FLATRATE_FROM_751) }}</li>
                <li>851. - 950.: {{ formatPrice(CALL_PRICE_FLATRATE_FROM_851) }}</li>
                <li>951. - 1.150.: {{ formatPrice(CALL_PRICE_FLATRATE_FROM_951) }}</li>
                <li>Ab 1.151.: {{ formatPrice(CALL_PRICE_FLATRATE_FROM_1151) }}</li>
            </ul>
        </div>

        <div id="flatrate-plan-transaction-tooltip">
            Transaktion = Kalkulation + Bewertung innerhalb eines Vorgangs.
            <br />
            <br />
            {{ ANNUAL_CALLS_INCLUDED_FLATRATE }} Transaktionen im Jahr entsprechen im Schnitt etwa
            {{ roundNumber(ANNUAL_CALLS_INCLUDED_FLATRATE / 12) }}
            Transaktionen pro Monat.
        </div>

        <!--********** Usage Based Calculations **********-->
        <div id="flatrate-plan-usage-based-transactions-tooltip">
            Für Transaktionen, die über das Freikontingent von
            {{ ANNUAL_CALLS_INCLUDED_FLATRATE }}
            Stück im Jahr hinausgehen, gelten Staffelpreise:
            <ul>
                <li>751. - 850.: {{ formatPrice(CALL_PRICE_FLATRATE_FROM_751) }}</li>
                <li>851. - 950.: {{ formatPrice(CALL_PRICE_FLATRATE_FROM_851) }}</li>
                <li>951. - 1.150.: {{ formatPrice(CALL_PRICE_FLATRATE_FROM_951) }}</li>
                <li>Ab 1.151.: {{ formatPrice(CALL_PRICE_FLATRATE_FROM_1151) }}</li>
            </ul>
            Eine Transaktion umfasst sowohl eine Kalkulation als auch eine Bewertung im selben Vorgang.
        </div>

        <!--********** Monthly 1st Year **********-->
        <div id="flatrate-price-first-year-monthly-tooltip">
            Der Preis im 1. Jahr enthält Einmalgebühren. Ab dem 2. Jahr ist der Preis deshalb geringer.
            <br />
            <br />

            Monatlicher Preis =
            {{
                formatPrice(
                    flatrateFirstYearMonthlyFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>
                    Ein Zwölftel des Jahrespreises ({{
                        formatPrice(
                            flatrateFirstYearAnnualFee(
                                numberOfUsers,
                                reportsPerMonth,
                                valuationsPerMonth,
                                includeRentalCarPricingService
                            ) / 12
                        )
                    }})
                </li>
                <li>Gebühr für monatliche Zahlweise ({{ formatPrice(MONTHLY_PAYMENT_CYCLE_FEE) }})</li>
            </ul>
            Jahrespreis =
            {{
                formatPrice(
                    flatrateFirstYearAnnualFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <ul>
                <li>Basislizenz ({{ formatPrice(DAT_LICENSE) }})</li>
                <!--                <li>Einweisung ({{ formatPrice(DAT_SETUP_AND_INSTRUCTION) }})</li>-->
                <li>
                    Monatl. Grundgebühr (12 *
                    {{ formatPrice(MONTHLY_BASE_FEE_FLATRATE) }} = {{ formatPrice(MONTHLY_BASE_FEE_FLATRATE * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth)) {
                    <li>
                        Zusätzl. Transaktionen / Jahr ({{
                            flatrateAdditionalCalls(reportsPerMonth, valuationsPerMonth)
                        }}
                        * Staffel =
                        {{ formatPrice(flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** Monthly from 2nd Year **********-->
        <div id="flatrate-price-from-second-year-monthly-tooltip">
            Monatlicher Preis =
            {{
                formatPrice(
                    flatrateMonthlyTotal(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>
                    Ein Zwölftel des Jahrespreises ({{
                        formatPrice(
                            flatrateAnnualTotal(
                                numberOfUsers,
                                reportsPerMonth,
                                valuationsPerMonth,
                                includeRentalCarPricingService
                            ) / 12
                        )
                    }})
                </li>
                <li>Gebühr für monatliche Zahlweise ({{ formatPrice(MONTHLY_PAYMENT_CYCLE_FEE) }})</li>
            </ul>
            Jahrespreis =
            {{
                formatPrice(
                    flatrateAnnualTotal(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <ul>
                <li>
                    Monatl. Grundgebühr (12 *
                    {{ formatPrice(MONTHLY_BASE_FEE_FLATRATE) }} = {{ formatPrice(MONTHLY_BASE_FEE_FLATRATE * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth)) {
                    <li>
                        Zusätzl. Transaktionen / Jahr ({{
                            flatrateAdditionalCalls(reportsPerMonth, valuationsPerMonth)
                        }}
                        * Staffel =
                        {{ formatPrice(flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** 1st Year Annually **********-->
        <div id="flatrate-price-first-year-annually-tooltip">
            Der Preis im ersten Jahr ist höher, weil er Einmalgebühren beinhaltet.
            <br />
            <br />

            Jahrespreis =
            {{
                formatPrice(
                    flatrateFirstYearAnnualFee(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>Basislizenz ({{ formatPrice(DAT_LICENSE) }})</li>
                <!--                <li>Einweisung ({{ formatPrice(DAT_SETUP_AND_INSTRUCTION) }})</li>-->
                <li>
                    Monatl. Grundgebühr (12 *
                    {{ formatPrice(MONTHLY_BASE_FEE_FLATRATE) }} = {{ formatPrice(MONTHLY_BASE_FEE_FLATRATE * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth)) {
                    <li>
                        Zusätzl. Transaktionen / Jahr ({{
                            flatrateAdditionalCalls(reportsPerMonth, valuationsPerMonth)
                        }}
                        * Staffel =
                        {{ formatPrice(flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>

        <!--********** 2nd+ Year Annually **********-->
        <div id="flatrate-price-from-second-year-annually-tooltip">
            Jahrespreis =
            {{
                formatPrice(
                    flatrateAnnualTotal(
                        numberOfUsers,
                        reportsPerMonth,
                        valuationsPerMonth,
                        includeRentalCarPricingService
                    )
                )
            }}
            <br />
            <ul>
                <li>
                    Monatl. Grundgebühr (12 *
                    {{ formatPrice(MONTHLY_BASE_FEE_FLATRATE) }} = {{ formatPrice(MONTHLY_BASE_FEE_FLATRATE * 12) }})
                </li>
                <li>
                    VIN-Abfragen ({{ vinCalls(reportsPerMonth, valuationsPerMonth) }} *
                    {{ formatPrice(VIN_CALL_PRICE) }} =
                    {{ formatPrice(vinCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                </li>
                <li>
                    AZT-Lack-Addon (12 * {{ formatPrice(MONTHLY_AZT_PAINT_SYSTEM_FEE) }} =
                    {{ formatPrice(12 * MONTHLY_AZT_PAINT_SYSTEM_FEE) }})
                </li>

                @if (includeRentalCarPricingService) {
                    <li>
                        Mietwagenspiegel (12 *
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE) }} =
                        {{ formatPrice(RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12) }})
                    </li>
                }
                @if (includeRentalCarPricingService && additionalRentalCarPricingCalls(reportsPerMonth)) {
                    <li>
                        Zusätzl. Mietwagenspiegel-Abrufe ({{ additionalRentalCarPricingCalls(reportsPerMonth) }} *
                        {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} =
                        {{ formatPrice(additionalRentalCarPricingCallsPrice(reportsPerMonth)) }})
                    </li>
                }
                @if (flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth)) {
                    <li>
                        Zusätzl. Transaktionen / Jahr ({{
                            flatrateAdditionalCalls(reportsPerMonth, valuationsPerMonth)
                        }}
                        * Staffel =
                        {{ formatPrice(flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth)) }})
                    </li>
                }
                @if (additionalUsers(numberOfUsers)) {
                    <li>
                        Zusatznutzer ({{ additionalUsers(numberOfUsers) }} * 12 *
                        {{ formatPrice(ADDITIONAL_USERS_FEE) }} =
                        {{ formatPrice(additionalUsersPrice(numberOfUsers) * 12) }})
                    </li>
                }
            </ul>
        </div>
        <!--============================================-->
        <!-- END Flatrate Tooltips -->
        <!--============================================-->

        <!--============================================-->
        <!-- Common Tooltips -->
        <!--============================================-->
        <!--********** DAT Base License **********-->
        <div id="dat-license-tooltip">
            Im Wert von {{ formatPrice(DAT_LICENSE) }}. Voraussetzung für die Nutzung jeder SilverDAT-Applikation.
        </div>

        <!--********** DAT Setup and Instructions (Per-case plan only) **********-->
        <div id="dat-setup-without-instruction-tooltip">
            Im Wert von {{ formatPrice(DAT_SETUP_WITHOUT_INSTRUCTION) }}. Einrichtung ohne Einweisung durch einen
            DAT-Mitarbeiter bei Ihnen vor Ort. Stattdessen können Online-Webinare à
            {{ formatPrice(WEBINAR_PRICE) }} gebucht werden.
        </div>

        <!--********** DAT Setup and Instructions (All but per-case plan) **********-->
        <div id="dat-setup-and-instruction-tooltip">
            Im Wert von {{ formatPrice(DAT_SETUP_AND_INSTRUCTION) }}. Einrichtung & eintägige Einweisung durch einen
            DAT-Mitarbeiter bei Ihnen vor Ort.
        </div>

        <!--********** Additional Users **********-->
        <div id="additional-users-tooltip">
            {{ USERS_INCLUDED }} Nutzer sind inklusive. Weitere Nutzer für
            {{ formatPrice(ADDITIONAL_USERS_FEE) }}
            pro Monat hinzubuchbar.
        </div>

        <!--********** VIN-Request **********-->
        <div id="vin-request-tooltip">Eine VIN-Abfrage ist der schnellste Weg zur Fahrzeugidentifikation.</div>

        <!--********** Rental Car Pricing **********-->
        <div id="rental-car-pricing-flat-fee-tooltip">
            Ermittlung des Nutzungsausfall und der Mietwagenpreise.<br><br>
            {{ ANNUAL_CALLS_INCLUDED_RENTAL_CAR }} Abrufe pro Jahr ({{
                roundNumber(ANNUAL_CALLS_INCLUDED_RENTAL_CAR / 12)
            }}
            pro Monat) inklusive. Danach {{ formatPrice(CALL_PRICE_RENTAL_CAR) }} pro Abruf.
        </div>
        <!--============================================-->
        <!-- END Common Tooltips -->
        <!--============================================-->
    </div>
</div>
