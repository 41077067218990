import { Component, inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { WebinarHeroComponent } from '../../components/webinar-hero/webinar-hero.component';
import {
    WebinarTargetGroupComponent,
    WebinarTargetGroupLevel,
} from '../../components/webinar-target-group/webinar-target-group.component';
import {
    WebinarInstructor,
    WebinarInstructorSectionComponent,
} from '../../components/webinar-instructor-section/webinar-instructor-section.component';
import { WEBINAR_INSTRUCTOR_PATRICK_KOHL } from '../../webinar-instructors';
import { WebinarAgendaComponent, WebinarContent } from '../../components/webinar-agenda/webinar-agenda.component';
import { getParameterByName } from '../../../../shared/lib/get-parameters-by-name';
import { FormsModule } from '@angular/forms';
import { GetWebinarTimePipe } from '../../pipes/get-webinar-time.pipe';
import { GetWebinarDateLongFormPipe } from '../../pipes/get-webinar-date-long-form.pipe';
import { GetWebinarDateLongFormWithWeekdayPipe } from '../../pipes/get-webinar-date-long-form-with-weekday.pipe';
import { WebinarSignupSectionComponent } from '../../components/webinar-signup-section/webinar-signup-section.component';
import { isPlatformBrowser } from '@angular/common';
import {ExpertWebinarFaqs} from "../../components/expert-webinar-faq/expert-webinar-faqs.component";

@Component({
    selector: 'ax-textbausteine-im-gutachten-kohl',
    standalone: true,
    imports: [
        GetWebinarTimePipe,
        GetWebinarDateLongFormPipe,
        GetWebinarDateLongFormWithWeekdayPipe,
        FormsModule,
        WebinarHeroComponent,
        WebinarAgendaComponent,
        WebinarTargetGroupComponent,
        WebinarHeroComponent,
        WebinarAgendaComponent,
        WebinarTargetGroupComponent,
        WebinarInstructorSectionComponent,
        WebinarSignupSectionComponent,
        ExpertWebinarFaqs,
    ],
    templateUrl: './textbausteine-im-gutachten-kohl.component.html',
    styleUrl: '../fachwebinare.scss',
})
export class TextbausteineImGutachtenKohlComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly httpClient = inject(HttpClient);
    protected readonly platformId = inject(PLATFORM_ID);

    protected webinarDate: string | null = null;
    protected webinarTitle = 'Textbausteine im Gutachten';
    protected webinarTitleShort = 'Textbausteine';
    protected targetGroupLevel: WebinarTargetGroupLevel = {
        beginner: true,
        intermediate: true,
        professional: false,
    };
    protected instructors: Array<WebinarInstructor> = [WEBINAR_INSTRUCTOR_PATRICK_KOHL];
    protected price: number = 149;
    protected duration = 2;
    protected maximumNumberOfParticipants = 60;
    protected eventOverbooked = false;
    protected webinarContents: Array<WebinarContent> = [
        {
            title: 'Allgemeine Anforderungen an Gutachten',
            description: '',
        },
        {
            title: 'Spezielle Anforderungen an die Vortexte und deren Inhalt',
            description: '',
        },
        {
            title: 'Spezielle Anforderungen an die Nachtexte und deren Inhalt',
            description: '',
        },
        {
            title: 'Beispiele für mögliche Textanpassungen',
            description: '',
        },
        {
            title: 'Mögliche Anlagen in Gutachten',
            description: '',
        },
    ];

    constructor() {
        this.titleService.setTitle(`${this.webinarTitleShort} | Fach-Webinar`);
        this.metaService.addTag({
            name: 'description',
            content: `Fachwebinar ${this.webinarTitle} Wissen für Kfz-Sachverständige - rund um autoiXpert`,
        });
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.webinarDate = atob(getParameterByName('webinarDate') ?? '');
        }
    }
}
