<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Welchem Haftungsrisiko unterliege ich, wenn ich Gutachten erstelle? Wann kann ich für einen Vermögensschadens
        belangt werden? Dieses Webinar zeigt die Risiken auf und gibt Tipps, wie diese Risiken umschifft werden können.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Du solltest teilnehmen, wenn du in das Thema Haftung einsteigen oder dein Grundlagenwissen vertiefen möchtest.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
